import './asserts/bootstrap/css/bootstrap-grid.min.css'
import './asserts/froala_style.css'
import './asserts/app.css'
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import InfiniteScroll from 'vue-infinite-scroll'
import CssParallax from './scripts/css-parallax'
import {resovleCover} from './scripts/functions'
import routes from './routes'

Vue.use(InfiniteScroll)
Vue.use(CssParallax)
Vue.use(VueRouter)
Vue.filter('cover', function (source, cut) {
  return resovleCover(source, cut)
})

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'on',
  routes
})

const app = new Vue({
  el: '#app',
  data: {
    loading: true
  },
  router, render: h => h(App)
})

router.beforeEach((to, from, next) => {
  app.loading = true
  next()
})

// new Vue({el: '#app', render: (h) => h('router-view', {attrs: {id: 'app'}}), router})
