var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app scrollbar" },
    [
      _c("NavMenu", {
        attrs: { open: _vm.navopen },
        on: {
          toggle: function ($event) {
            _vm.navopen = !_vm.navopen
          },
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: _vm.transition } },
        [
          _c("router-view", {
            key: _vm.$route.path,
            staticClass: "router-view",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", [
      _vm._v("\n    © " + _vm._s(_vm.year) + " UPDESIGN |\n    "),
      _c(
        "a",
        { attrs: { href: "http://www.beian.gov.cn", target: "_blank" } },
        [_vm._v("粤公网安备44030502006155号")]
      ),
      _vm._v(" |\n    "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
        [_vm._v("粤ICP备2020093518号")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }