<template>
  <header :class="open ? 'nav-open' : ''">
    <main class="container-xxl d-flex">
      <router-link to="/" class="mr-auto pl-3" exact>
        <img class="logo logo-dark" src="/static/img/logo-dark.png" alt="Logo">
        <img class="logo" src="/static/img/logo.png" alt="Logo">
      </router-link>

      <nav class="nav-def" ref="navdef">

        <form class="flex-middle search-input" @submit="onSearch($event)">
          <input id="search-input" ref="q" placeholder="搜索" autocomplete="off">
          <label for="search-input" class="iconfont icon-search" title="搜索" @click="labelClick($event)"></label>
        </form>

        <router-link to="/" exact>首页</router-link>
        <router-link to="/projects">项目作品</router-link>
        <router-link to="/news" class="parent">资讯
          <ul class="dropdown">
            <li>
              <router-link to="/news">动态</router-link>
            </li>
            <!--<li><router-link to="/memories">团队记忆</router-link></li>-->
            <li>
              <router-link to="/job">招聘</router-link>
            </li>
          </ul>
        </router-link>
        <router-link to="/about">关于我们</router-link>
        <!--
        <router-link to="/about" class="parent">关于我们
          <ul class="dropdown">
            <li><router-link to="/about">公司介绍</router-link></li>
            <li><router-link to="/members">团队核心</router-link></li>
          </ul>
        </router-link>
        -->
        <router-link to='/contact'>联系我们</router-link>
      </nav>
    </main>

    <nav ref="navmob" class="nav-mob" :class="menu ? 'open ' + menu : ''">
      <div class="nav-mob-inner">
        <router-link to="/" exact>首页</router-link>
        <router-link to="/projects">项目作品</router-link>
        <a class="parent" @click="toggle('dropdown-1')">资讯</a>
        <!--<a class="parent" @click="toggle('dropdown-2')">关于我们</a>-->
        <router-link to='/about'>关于我们</router-link>
        <router-link to='/contact'>联系我们</router-link>
      </div>
      <div class="dropdown dropdown-1">
        <router-link to="/news">动态</router-link>
        <!--<li><router-link to="/memories">团队记忆</router-link></li>-->
        <router-link to="/job">招聘</router-link>
        <a class="iconfont icon-arrow-left font-xxl pt-3" @click="toggle('')"></a>
      </div>
      <!--
      <div class="dropdown dropdown-2">
        <router-link to="/about">公司介绍</router-link>
        <router-link to="/members">团队核心</router-link>
        <a class="iconfont icon-arrow-left font-xxl pt-3" @click="toggle('')"></a>
      </div>
      -->
    </nav>

    <a class="nav-toggle" @click="$emit('toggle')"><i></i></a>
  </header>
</template>

<script>

export default {
  name: 'HeaderNav',
  props: {
    open: Boolean
  },
  data: function () {
    return {
      menu: ''
    }
  },
  mounted () {
    var self = this
    var navmob = self.$refs.navmob
    // var header = document.querySelector('header')
    // mouseenter
    // mouseleave
    document.body.addEventListener('click', function (e) {
      var target = e.target
      if (self.open) {
        if (!navmob.contains(target) && target.className !== 'nav-toggle') {
          self.$emit('toggle')
          self.menu = ''
        }
      }
    }, true)
  },
  methods: {
    labelClick: function (e) {
      var input = e.target.previousElementSibling
      var width = getComputedStyle(input).width
      var number = parseInt(width)
      if (number > 50) {  // input 宽度大于50px时，提交表单
        e.preventDefault()
        this.onSearch()
      }
    },
    onSearch: function (e) {
      if (e) {
        e.preventDefault()
      }
      var q = this.$refs.q.value.trim()
      if (!q) {
        return
      }
      this.$router.push('/search?q=' + q)
      // this.$router.push({path: 'search', query: {q: this.$refs.q.value}})
    },
    toggle: function (m) {
      console.log(m)
      this.menu = m
    }
  },
  watch: {
    'open': function (val) {
      if (!val) {
        this.menu = ''
      }
    }
  }
}
</script>

<style>
header {
  background: rgba(0, 0, 0, .5);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .35) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  line-height: 5rem;
  transition: all .5s ease;
  z-index: 9;
  width: 100%;
  color: #fff;
  top: 0;
}
.logo {
  max-width: 80px;
}
.logo-dark {
  display: none;
  max-height: 60px;
}
.whited .logo-dark {
  display: inline-block;
}
.whited .router-view {
  padding-top: 4.75rem;
}
.whited header {
  background: #fff;
  line-height: 4.75rem;
  color: #333;
}
.whited .nav-toggle {
  color: #000;
}
.search-input {
  position: relative;
  align-content: flex-end;
}
.search-input input {
  font-size: .85rem;
  background: transparent;
  padding: .25rem 2.25rem .25rem .75rem;
  border-radius: 50rem;
  will-change: width;
  outline: 0;
  height: 2rem;
  border: 1px solid transparent;
  transition: all .5s ease;
  width: 2rem;
}
.search-input input:focus {
  border: 1px solid #fff;
  width: 10rem;
}
.whited .search-input input:focus {
  border: 1px solid #3db24b;
}
.search-input input, .search-input label {
  color: #fff;
}
.whited .search-input input, .whited .search-input label {
  color: #333;
}
.search-input input:focus + label {
  color: #fff;
}

.whited .search-input input:focus + label {
  color: #3db24b;
}

.search-input label {
  position: absolute;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.65;
  right: .65rem;
}
nav a {
  font-size: .85rem;
  text-align: center;
}
nav a.on {
  font-weight: bold;
}
.nav-def {
  display: flex;
  align-items: center;
}
.nav-def a {
  position: relative;
}
.nav-def > a {
  min-width: 6rem;
}
.whited .nav-def a {
  text-shadow: none;
}
.whited .nav-def > a:hover {
  background: rgba(0, 0, 0, .15);
}
.nav-def .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  min-width: 6rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  float: left;
  padding: .5rem 0 1rem;
  color: #212529;
  background: rgba(0, 0, 0, .5);
  background-clip: padding-box;
  box-shadow: 0 0 30px rgba(0, 0, 0, .001);
  border-radius: 0 0 3px 3px;
  line-height: 2.5rem;
  transition: all .2s ease-in-out;
  transform-origin: 0 0;
  transform: scale(1, 0);
}
.nav-def .dropdown a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #fff;
}
.nav-def .dropdown a:after {
  content: "";
  position: absolute;
  transition: all .2s ease-in-out 0s;
  background: #fff;
  visibility: hidden;
  transform: scaleX(0);
  height: 1.5px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.nav-def .dropdown a:hover {
  font-weight: bold;
  /*color: #ccc;*/
}
.nav-def .dropdown a:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.nav-def a:hover .dropdown {
  pointer-events: auto;
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}
.parent.open .dropdown {
  pointer-events: auto;
  visibility: visible;
  display: block;
  opacity: 1;

}

/********** mobile nav ***********/
.nav-mob {
  background: rgba(0, 0, 0, 0.55);
  transform-origin: 100% 0;
  transition: all .3s ease-out;
  transform: translateX(-50%) scale(0);
  border-radius: 4px;
  text-align: center;
  position: absolute;
  overflow: hidden;
  min-width: 70%;
  left: 50%;
  top: 5rem;
}
.nav-mob-inner {
  transition: all .3s ease-out;
  padding: 3rem 0;
  opacity: 1;
}
.nav-mob a {
  position: relative;
  display: block;
  line-height: 3rem;
  margin: 0 auto;
  width: 7rem;
  color: #fff;
}
.nav-mob a + a {
  border-top: 1px solid #e6e6e6;
}
.nav-mob .dropdown {
  position: absolute;
  transition: all .3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  pointer-events: none;
  transform: scale(.8);
  opacity: 0
}
.nav-mob.open .nav-mob-inner {
  pointer-events: none;
  transform: scale(1.2);
  opacity: 0;
}
.dropdown-1 .dropdown-1,
.dropdown-2 .dropdown-2 {
  pointer-events: auto;
  transform: scale(1);
  opacity: 1;
}

.nav-toggle {position: relative; display: inline-block; height: 3rem; width: 3rem; cursor: pointer; margin-left: auto; color: #fff}
.nav-toggle i {left: 50%;top: 50%;bottom: auto;right: auto;transform: translateX(-50%) translateY(-50%);transition: background-color .3s; pointer-events: none}
.nav-toggle i, .nav-toggle i::before, .nav-toggle i::after {position: absolute;background-color: currentColor;height: 1.5px;width: 1.25rem;}
.nav-toggle i::before, .nav-toggle i::after {content: '';left: 0;transition: transform .3s;}
.nav-toggle i::before {transform: translateY(-7px);}
.nav-toggle i::after {transform: translateY(7px);}
.nav-open .nav-toggle i {background-color: transparent;}
.nav-open .nav-toggle i::before {transform: rotate(45deg);}
.nav-open .nav-toggle i::after {transform: rotate(-45deg);}
.nav-open nav {
  transform: translateX(-50%) scale(1);
}
.header-padding { padding-top: 5rem }
@media (max-width: 768px) {
  .header-padding { padding-top: 0 }

  .nav-def { display: none }
}
@media (min-width: 768px) {
  .nav-toggle, .nav-mob { display: none }
}
</style>
