function disableContextmenu (tag, cb) {
  document.oncontextmenu = function (e) {
    var el = e.target
    if (el.tagName === tag.toUpperCase()) {
      cb && cb(e, el)
      return false
    }
  }
}

function showTooltip (e, html) {
  var tooltip = getTooltipDiv(html || e.dataset.tooltip)

  let bodyWidth = document.body.clientWidth
  let bodyHeight = document.body.clientHeight
  let elHeight = tooltip.clientHeight
  let elWidth = tooltip.clientWidth

  console.log(e.pageX, e.pageY)

  tooltip.style.left =
    (e.pageX + elWidth + 10 < bodyWidth)
      ? (e.pageX + 10 + 'px')
      : (bodyWidth + 5 - elWidth + 'px')

  tooltip.style.top =
    (e.pageY + elHeight + 10 < bodyHeight)
      ? (e.pageY + 10 + 'px')
      : (bodyHeight + 5 - elHeight + 'px')

  // tooltip.addEventListener('mousemove', showTooltip)
  tooltip.style.display = 'block'

  if (tooltip.timer) {
    clearTimeout(tooltip.timer)
  }
  tooltip.timer = setTimeout(function () { tooltip.style.display = 'none' }, 1500)
}

function getTooltipDiv (html) {
  var el = document.querySelector('.tooltip')
  if (!el) {
    el = document.createElement('div')
    document.body.appendChild(el)
  }
  el.classList.add('tooltip')
  el.innerHTML = html
  return el
}

function resovleCover (source, cut) {
  var arr = source.split(',')
  return cut ? arr[arr.length - 1] + '!squa' : arr[0]
}

export {
  disableContextmenu,
  resovleCover,
  showTooltip
}
