var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { class: _vm.open ? "nav-open" : "" }, [
    _c(
      "main",
      { staticClass: "container-xxl d-flex" },
      [
        _c(
          "router-link",
          { staticClass: "mr-auto pl-3", attrs: { to: "/", exact: "" } },
          [
            _c("img", {
              staticClass: "logo logo-dark",
              attrs: { src: "/static/img/logo-dark.png", alt: "Logo" },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "logo",
              attrs: { src: "/static/img/logo.png", alt: "Logo" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "nav",
          { ref: "navdef", staticClass: "nav-def" },
          [
            _c(
              "form",
              {
                staticClass: "flex-middle search-input",
                on: {
                  submit: function ($event) {
                    return _vm.onSearch($event)
                  },
                },
              },
              [
                _c("input", {
                  ref: "q",
                  attrs: {
                    id: "search-input",
                    placeholder: "搜索",
                    autocomplete: "off",
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "iconfont icon-search",
                  attrs: { for: "search-input", title: "搜索" },
                  on: {
                    click: function ($event) {
                      return _vm.labelClick($event)
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/", exact: "" } }, [
              _vm._v("首页"),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/projects" } }, [
              _vm._v("项目作品"),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "parent", attrs: { to: "/news" } },
              [
                _vm._v("资讯\n        "),
                _c("ul", { staticClass: "dropdown" }, [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/news" } }, [
                        _vm._v("动态"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/job" } }, [
                        _vm._v("招聘"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/about" } }, [
              _vm._v("关于我们"),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/contact" } }, [
              _vm._v("联系我们"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "nav",
      {
        ref: "navmob",
        staticClass: "nav-mob",
        class: _vm.menu ? "open " + _vm.menu : "",
      },
      [
        _c(
          "div",
          { staticClass: "nav-mob-inner" },
          [
            _c("router-link", { attrs: { to: "/", exact: "" } }, [
              _vm._v("首页"),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/projects" } }, [
              _vm._v("项目作品"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "parent",
                on: {
                  click: function ($event) {
                    return _vm.toggle("dropdown-1")
                  },
                },
              },
              [_vm._v("资讯")]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/about" } }, [
              _vm._v("关于我们"),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/contact" } }, [
              _vm._v("联系我们"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown dropdown-1" },
          [
            _c("router-link", { attrs: { to: "/news" } }, [_vm._v("动态")]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/job" } }, [_vm._v("招聘")]),
            _vm._v(" "),
            _c("a", {
              staticClass: "iconfont icon-arrow-left font-xxl pt-3",
              on: {
                click: function ($event) {
                  return _vm.toggle("")
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "nav-toggle",
        on: {
          click: function ($event) {
            return _vm.$emit("toggle")
          },
        },
      },
      [_c("i")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }