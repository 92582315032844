function directive (el, binding, vnode) {

  const mobile_off = binding.arg == 'mobileoff'

  // DISABLE ON MOBILE IF ARG `mobileoff` IS SET
  if (window.innerWidth < 768 && mobile_off) {
    return false // do something
  }


  window.requestAnimationFrame(setScrollParallax)

  function setScrollParallax () {

    let scroll = el.parentNode.scrollTop
    /*
    if (scroll == 0) {
      // if center modifier is true, we want to start the magic from the middle of the screen
      let winpos = window.innerHeight
      if (binding.modifiers.center) {
        winpos /= 2
      }
      // document scrolled amount - parallax container offset from top + window height
      // this make sure to update --scroll-amount only when the elements are in the viewport
      scroll = document.documentElement.scrollTop - el.offsetTop + winpos
      // Make sure we dont have negative values
    }
    */
    if (scroll < 0) {
      scroll = 0
    }

    // set the "--scroll-amount" for each parallax container
    el.parentNode.style.setProperty('--scroll', scroll)

    // global "--scroll-amount" attached to the body
    // document.body.style.setProperty("--scroll-amount", document.documentElement.scrollTop );

    window.requestAnimationFrame(setScrollParallax)
  }
}

function install (Vue) {
  Vue.directive('css-parallax', {
    bind: directive
  })
}

export { directive, install }

export default install
