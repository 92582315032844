export default [
  {
    path: '/',
    name: 'Home',
    meta: {
      lvl: 1,
      idx: 1
    },
    component: () => import(/* webpackChunkName: "projects" */ './Home.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: {
      lvl: 1,
      idx: 2
    },
    component: () => import(/* webpackChunkName: "projects" */ './Projects.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      lvl: 1,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "news" */ './News.vue')
  },
  {
    path: '/about',
    name: 'Aontact',
    meta: {
      lvl: 1,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "about" */ './About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      lvl: 1,
      idx: 4
    },
    component: () => import(/* webpackChunkName: "contact" */ './Contact.vue')
  },
  {
    path: '/project/:id',
    name: 'Project',
    meta: {
      lvl: 2,
      idx: 1
    },
    component: () => import(/* webpackChunkName: "project" */ './Project.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsItem',
    meta: {
      lvl: 2,
      idx: 2
    },
    component: () => import(/* webpackChunkName: "newsitem" */ './NewsItem.vue')
  },
  {
    path: '/memories',
    name: 'Memories',
    meta: {
      lvl: 1,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "memories" */ './Memories.vue')
  },
  {
    path: '/memory/:id',
    name: 'Memory',
    meta: {
      lvl: 2,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "memory" */ './Memory.vue')
  },
  {
    path: '/job',
    name: 'Job',
    meta: {
      lvl: 2,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "job" */ './Job.vue')
  },
  {
    path: '/members',
    name: 'Members',
    meta: {
      lvl: 2,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "members" */ './Members.vue')
  },
  {
    path: '/member/:id',
    name: 'Member',
    meta: {
      lvl: 2,
      idx: 3
    },
    component: () => import(/* webpackChunkName: "member" */ './Member.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      lvl: 2,
      idx: 1
    },
    component: () => import(/* webpackChunkName: "search" */ './Search.vue')
  }
]
