<template>
  <div class="app scrollbar">
    <NavMenu :open="navopen" @toggle="navopen = !navopen"/>
    <transition :name="transition">
      <router-view class="router-view" :key="$route.path"/>
    </transition>
    <footer v-once>
      © {{ year }} UPDESIGN |
      <a href="http://www.beian.gov.cn" target="_blank">粤公网安备44030502006155号</a> |
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2020093518号</a>
    </footer>
  </div>
</template>

<script>
import { disableContextmenu, showTooltip } from './scripts/functions'
import NavMenu from './NavMenu.vue'

export default {
  components: {NavMenu},
  data: () => ({
    transition: 'slide-left',
    navopen: false,
    year: new Date().getFullYear()
  }),
  mounted () {
    disableContextmenu('img', function (e, img) {
      showTooltip(e, '作品已设置版权保护')
    })
  },
  watch: {
    $route (to, from) {
      this.navopen = false
      this.$el.classList[to.path === '/' ? 'remove' : 'add']('whited')

      var dir = to.query.dir
      if (!dir) {
        let fm = from.meta,
            tm = to.meta
        if (fm.lvl === tm.lvl) {
          if (tm.idx === fm.idx) {
            dir = to.path > from.path ? 'right' : 'left'
          } else {
            dir = tm.idx > fm.idx ? 'left' : 'right'
          }
        } else {
          dir = tm.lvl > fm.lvl ? 'up' : 'down'
        }
      }
      this.transition = 'slide-' + dir
    }
  }
}
</script>

<style>
body.loaded .curtain {
  transform: translateX(-100%);
}
footer {
  font-family: Tahoma;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #000;
  text-transform: uppercase;
  text-align: center;
  font-size: .625rem;
  line-height: 2rem;
  height: 2rem;
  color: #999;
  overflow: hidden;
}
footer a:hover {
  color: #900;
}
.spinner, .curtain {
  background-image: url(../static/img/loading.gif);
  background-repeat: no-repeat;
  background-position: center;
}
.curtain {
  transition: transform 1s cubic-bezier(0.87, 0, 0.15, 1);
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}
.router-view {
  /*transition: all 1s cubic-bezier(.55, 0, .1, 1);*/
  transition: all 1s cubic-bezier(0.87, 0, 0.15, 1);
  position: absolute;
  width: 100%;
  bottom: 2rem;
  overflow-x: hidden;
  top: 0;
}

.slide-left-enter,
.slide-right-leave-active {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-leave-active,
.slide-right-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-up-enter,
.slide-down-leave-active {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-leave-active,
.slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
